/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --star-size: 18px;
    --plus-minus-icon-size: 24px;
    --wishlist-heart-size: 23px;
}

@mixin icon-black-hover-effect {
    fill: var(--color-black);

    &:hover {
        fill: var(--primary-base-color);
    }
}

@mixin icon-primary {
    fill: var(--primary-base-color);

    @include hoverable { 
        &:hover {
            fill: var(--primary-dark-color);
        }
    }

    &:active {
        fill: var(--primary-dark-color);
    }
}

@mixin not-available {
    content: '';
    position: absolute;
    width: 1px;
    height: 32px;
    transform-origin: 50% 50%;
    inset-inline-start: 15px;
    inset-block-start: 1px;
    transform: rotate(45deg);
    box-shadow: none;
    border-radius: 0;
    opacity: 1;
}

@mixin clear-button {
    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        inset-block-start: 12px;
        inset-inline-start: 12px;
        width: 10px;
        height: 14px;
        transform: perspective(10px) rotateX(-10deg);
        border: {
            inset-inline-start: 1px solid var(--header-color);
            inset-inline-end: 1px solid var(--header-color);
            inset-block-end: 1px solid var(--header-color);
            bottom-left-radius: 3px;
            bottom-right-radius: 3px;
        }
    }

    &::after {
        inset-inline-start: 14px;
        inset-block-start: 7px;
        width: 6px;
        height: 2px;
        box-shadow: 6px 2px 0 -1px var(--header-color), -6px 2px 0 -1px var(--header-color), -2px 2px 0 -1px var(--header-color), 2px 2px 0 -1px var(--header-color);
        border: {
            inset-inline-start: 1px solid var(--header-color);
            inset-inline-end: 1px solid var(--header-color);
            inset-block-start: 1px solid var(--header-color);
            top-left-radius: 2px;
            top-right-radius: 2px;
        };
    }
}

@mixin close-button {
    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
        transform-origin: 50% 50%;
        inset-block-start: 7px;
        transform: rotate(-45deg);
        background-color: $c-gray-c;
    }

    &:hover {
        &::before,
        &::after {
            background-color: $c-red;
        }
    }

    &::after {
        transform: rotate(45deg);
    }
}

@mixin back-button {
    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        width: 18px;
        height: 1px;
        inset-block-start: 17px;
        inset-inline-end: 8px;
        background-color: var(--header-color);
    }

    &::after {
        width: 8px;
        height: 8px;
        inset-block-start: 13px;
        inset-inline-start: 10px;
        transform: rotate(-45deg);

        border: {
            inset-block-start: 1px solid var(--header-color);
            inset-inline-start: 1px solid var(--header-color);
        }
    }
}

@mixin menu-button {
    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::after {
        width: 7px;
        height: var(--header-icon-stroke-width);
        inset-block-start: 12px;
        inset-inline-start: 8px;
        background-color: var(--header-color);
        box-shadow: 3px 0 0 0 var(--header-color), 0 6px 0 0 var(--header-color), 2px 6px 0 0 var(--header-color), 0 12px 0 0 var(--header-color), 6px 12px 0 0 var(--header-color), 10px 12px 0 0 var(--header-color);

        @include desktop {
            width: 20px;
            box-shadow: 0 6px 0 0 var(--header-color), 0 12px 0 0 var(--header-color);
        }
    }

    &::before {
        @include before-desktop {
            width: calc(12px - var(--header-icon-stroke-width) * 2);
            height: calc(12px - var(--header-icon-stroke-width) * 2);
            border: var(--header-icon-stroke-width) solid var(--header-color);
            border-radius: 50%;
            inset-block-start: 10px;
            inset-inline-end: 4px;
            box-shadow: 4px 4px 0 -5px var(--header-color), 5px 5px 0 -5px var(--header-color), 6px 6px 0 -5px var(--header-color), 7px 7px 0 -5px var(--header-color), 8px 8px 0 -5px var(--header-color);
        }

        @include desktop {
            content: 'menu';
            color: var(--header-color);
            text-transform: uppercase;
            inset-inline-start: 35px;
            inset-block-start: 11px;
            font-size: 12px;
        }
    }
}

@mixin account-button {
    &::before,
    &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        inset-inline-end: 0;
        inset-inline-start: 0;
        margin: auto;
        border: var(--header-icon-stroke-width) solid var(--header-color);
    }

    &::before {
        height: calc(9px - var(--header-icon-stroke-width) * 2);
        inset-block-start: 8px;
        width: calc(9px - var(--header-icon-stroke-width) * 2);
    }

    &::after {
        inset-block-start: 19px;
        width: calc(17px - var(--header-icon-stroke-width) * 2);
        height: calc(17px - var(--header-icon-stroke-width) * 2);
        clip-path: polygon(0 0, 0 50%, 100% 50%, 100% 0);
    }
}

@mixin minicart-button {
    &::before,
    &::after {
        content: '';
        position: absolute;
        inset-inline-start: 0;
        inset-inline-end: 0;
        margin: auto;
    }

    &::before {
        border: var(--header-icon-stroke-width) solid var(--header-color);
        border-radius: 1px;
        width: calc(16px - var(--header-icon-stroke-width) * 2);
        height: calc(14px - var(--header-icon-stroke-width) * 2);
        inset-block-start: 13px;
        z-index: 1;

        @include desktop {
            height: calc(17px - var(--header-icon-stroke-width) * 2);
            inset-inline-start: 3px;
            inset-block-start: 2px;
            width: calc(15px - var(--header-icon-stroke-width) * 2);
        }
    }

    &::after {
        width: calc(8px - var(--header-icon-stroke-width));
        height: 5px;
        inset-block-start: 7px;
        border-top: var(--header-icon-stroke-width) solid var(--header-color);
        border-left: var(--header-icon-stroke-width) solid var(--header-color);
        border-right: var(--header-icon-stroke-width) solid var(--header-color);
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;

        @include desktop {
            height: 8px;
            inset-inline-start: 3px;
            inset-block-start: -3px;
            width: calc(6px - var(--header-icon-stroke-width));
        }
    }
}

@mixin clear-search-button {
    @include close-button;

    border-radius: 50%;
    background-color: var(--search-clear-background);
    height: 15px;
    width: 15px;
    position: absolute;
    inset-inline-end: 2rem;

    &::before,
    &::after {
        height: 9px;
        width: 1px;
        inset-block-start: 3px;
        inset-inline-start: 7px;
        background-color: var(--header-background);
    }
}

@mixin edit-button {
    // border-radius: 10px; <- Wait chrome until bug will be fixed
    box-shadow: inset 0 0 0 8px var(--header-background), inset 0 0 0 9px var(--header-color);

    &::before,
    &::after {
        content: '';
        position: absolute;
        transform-origin: 0 0;
        transform: rotate(45deg);
        inset-block-start: 4px;
        inset-inline-end: 2px;
    }

    &::before {
        background-color: var(--header-background);
        box-shadow: 0 0 0 2px var(--header-background);
        width: 4px;
        height: 16px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: {
            inset-block-start: 1px solid var(--header-color);
            inset-inline-start: 1px solid var(--header-color);
            inset-inline-end: 1px solid var(--header-color);
        }
    }

    &::after {
        background-color: var(--header-color);
        width: 6px;
        inset-block-start: 4px;
        height: 23px;
        clip-path: polygon(
            3px 23px,
            7px 17px,
            3px 17px,
            3px 5px,
            0 5px,
            0 4px,
            5px 4px,
            5px 5px,
            3px 5px,
            3px 17px,
            -1px 17px,
            3px 23px,
            3px 22px,
            1px 18px,
            5px 18px,
            3px 22px,
        );
    }
}

@mixin lock-button {
    display: inline-block;
    margin-inline-end: 9px;

    &::before,
    &::after {
        content: '';
    }

    &::after {
        display: block;
        position: relative;
        inset-block-start: 1px;
        width: 10px;
        height: 9px;
        background: currentColor;
        border-radius: 1px;
        box-shadow: inset 0 0 0 -2px var(--button-background);
    }

    &::before {
        position: absolute;
        inset-inline-start: 0;
        inset-inline-end: 0;
        margin: auto;
        inset-block-start: -4px;
        border-radius: 50%;
        border: 1px solid currentColor;
        width: 6px;
        height: 6px;
    }
}

@mixin home-icon {
    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        border: var(--header-icon-stroke-width) solid var(--header-color);
        border-top: 0;
        border-radius: 1px;
        width: calc(14px - var(--header-icon-stroke-width) * 2);
        height: 10px;
        inset-block-start: 16px;
        inset-inline-end: 0;
        inset-inline-start: 0;
        z-index: 1;
        margin: auto;
    }

    &::after {
        transform: rotate(45deg);
        border-top: var(--header-icon-stroke-width) solid var(--header-color);
        border-left: var(--header-icon-stroke-width) solid var(--header-color);
        width: 13px;
        height: 13px;
        inset-block-start: 12px;
        inset-inline-start: 0;
        inset-inline-end: 0;
        margin: auto;
    }
}

@mixin search-icon {
    &::before {
        content: '';
        position: absolute;
        width: calc(10px - var(--header-icon-stroke-width) * 2);
        height: calc(10px - var(--header-icon-stroke-width) * 2);
        border: var(--header-icon-stroke-width) solid var(--header-color);
        border-radius: 50%;
        box-shadow: 4px 4px 0 -4px var(--header-color), 5px 5px 0 -4px var(--header-color), 6px 6px 0 -4px var(--header-color), 7px 7px 0 -4px var(--header-color);
    }
}

@mixin ios-share {
    $color-dodger-blue: #3b9cfe;

    border: 2px solid $color-dodger-blue;
    inset-block-end: -3px;
    height: 18px;
    margin: 0 4px;
    width: 18px;

    &::before {
        content: '';
        border-left: 2px solid $color-dodger-blue;
        border-top: 2px solid $color-dodger-blue;
        display: block;
        height: 6px;
        inset-inline-start: 3px;
        position: relative;
        inset-block-start: -9px;
        transform: rotate(45deg);
        width: 6px;
    }

    &::after {
        border-left: 2px solid $color-dodger-blue;
        content: '';
        display: block;
        height: 16px;
        inset-inline-start: 6px;
        position: relative;
        inset-block-start: -16px;
        width: 19px;
    }
}

@mixin ios-plus {
    $color-storm-grey: #7b7b86;

    background: linear-gradient($white, $white), linear-gradient($white, $white), $color-storm-grey;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 53% 1px, 1px 50%;
    border-radius: 5px;
    height: 20px;
    margin: 0 4px;
    width: 20px;
}

@mixin rtl-icon {
    [dir="rtl"] & {
        transform: scaleX(-1);
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/util';
@import '../../../../../src/style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 *
 * This file swaps around the responsibility of rendering the separator to the element below it, to make it easier to not render incorrect separators.
 */

// Use extra selector to prevent needing !important by providing a more precise selector.
.CartOverlay-Group {
    .CartOverlay {
        &-SubTotal,
        &-Tax {
            border-bottom: none;

            &_hasSeparator {
                border-bottom: solid 1px var(--color-input-border);
            }
        }

        &-NoSeparator {
            border-bottom: none;
        }

        &-Customs {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-transform: uppercase;
            padding: 8px 0;
            border-bottom: solid 1px var(--color-input-border);

            dd {
                font-size: 13px;
            }
        }
    }
}
